<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    textContent() {
      return this.block.fields.Text;
    },
    leftMedia() {
      const media = this.block.fields.ContentLeft;
      const overlays = this.overlayPositions();
      return {
        isVideo: this.isVideo(media),
        isAutoplay: this.isAutoplay('Left'),
        mediaSource: this.getMediaSource(media),
        element: this.getElementType(),
        link: this.imageLink(),
        overlays,
        overlayClasses: this.addOverlayClasses(overlays),
      };
    },
    rightMedia() {
      const media = this.block.fields.ContentRight;
      const overlays = this.overlayPositions('Right');
      return {
        isVideo: this.isVideo(media),
        isAutoplay: this.isAutoplay('Right'),
        mediaSource: this.getMediaSource(media),
        element: this.getElementType('Right'),
        link: this.imageLink('Right'),
        overlays,
        overlayClasses: this.addOverlayClasses(overlays),
      };
    },
  },
  methods: {
    isVideo(media) {
      return media?.templateId === 'Movie';
    },
    isAutoplay(mediaPosition) {
      return this.block.fields[`AutoplayVideo${mediaPosition}`];
    },
    getMediaSource(media) {
      if (!media) { return ''; }
      return this.$toLitiumMediaUrl(media?.id, { maxWidth: 700 });
    },
    getElementType(mediaPosition = '') {
      return this.imageLink(mediaPosition) ? 'router-link' : 'div';
    },
    imageLink(mediaPosition = '') {
      const { fields } = this.block;
      return fields[`CompositeLink_Link${mediaPosition}`] || undefined;
    },
    overlayPositions(mediaPosition = '') {
      return this.block.fields[`Overlays${mediaPosition}`]?.reduce((res, overlay) => {
        const positions = [];
        const posPhone = overlay.OverlayPositionPhone?.value || 'center';
        const posDesktop = overlay.OverlayPositionDesktop?.value || 'center';

        function addPosition(value) {
          if (value === 'hidden') {
            return;
          }
          if (value && positions.indexOf(value) < 0) {
            positions.push(value);
          }
        }

        addPosition(posPhone);
        addPosition(posDesktop);

        positions.forEach((pos) => {
          if (!res[pos]) {
            res[pos] = [];
          }
          const showOn = [];
          if (pos === posPhone) {
            showOn.push('phone');
          }
          if (pos === posDesktop) {
            showOn.push('desktop');
          }
          res[pos].push({
            showOn,
            overlay,
          });
        });
        return res;
      }, {});
    },
    getOverlayClassFor(item) {
      const classes = [
        'text-with-two-images-block__overlay-item',
        `text-with-two-images-block__overlay-item--phone-text-${
          item.overlay.OverlayTextAlignmentPhone?.value || 'center'
        }`,
        `text-with-two-images-block__overlay-item--desktop-text-${
          item.overlay.OverlayTextAlignmentDesktop?.value || 'center'
        }`,
        `text-with-two-images-block__overlay-item--${this.block.systemId}`,
      ];
      item.showOn.forEach((device) => {
        classes.push(`text-with-two-images-block__overlay-item--${device}`);
      });
      return classes;
    },
    addOverlayClasses(overlays) {
      if (!overlays) { return null; }

      return Object.keys(overlays).map((position) => {
        return overlays[position].map((i) => this.getOverlayClassFor(i));
      });
    },
  },
};
</script>

<template>
  <div
    class="text-with-two-images-block"
  >
    <div class="text-with-two-images-block__positioning-wrapper">
      <component
        :is="rightMedia.element"
        :to="rightMedia.link"
        :class="[
          'text-with-two-images-block__right-media',
          {
            'text-with-two-images-block__right-media--single':
              !rightMedia,
          },
        ]"
        v-if="rightMedia.mediaSource"
      >
        <img
          class="text-with-two-images-block__right-image"
          v-if="!rightMedia.isVideo"
          :src="rightMedia.mediaSource"
        />
        <video
          class="text-with-two-images-block__right-video"
          v-if="rightMedia.isVideo"
          :autoplay="rightMedia.isAutoplay"
          :loop="rightMedia.isAutoplay"
          :muted="rightMedia.isAutoplay"
          :controls="!rightMedia.isAutoplay"
          playsinline
        >
          <source :src="rightMedia.mediaSource" />
        </video>
        <div
          v-for="(position, name, overlayIndex) in rightMedia.overlays"
          :key="name"
          :class="[`text-with-two-images-block__overlay--${name}`,
            'text-with-two-images-block__overlay']"
        >
          <div class="text-with-two-images-block__overlay-container">
            <div
              v-for="(item, positionIndex) in position"
              :key="item"
              v-html="item.overlay.OverlayBody"
              :class="rightMedia.overlayClasses[overlayIndex][positionIndex]"
            ></div>
          </div>
        </div>
      </component>
      <div class="text-with-two-images-block__text" v-html="textContent"></div>
    </div>
    <component
      :is="leftMedia.element"
      :to="leftMedia.link"
      class="text-with-two-images-block__left-media"
      v-if="leftMedia.mediaSource"
    >
      <img
        class="text-with-two-images-block__left-image"
        v-if="!leftMedia.isVideo"
        :src="leftMedia.mediaSource"
      />
      <video
        class="text-with-two-images-block__left-video"
        v-if="leftMedia.isVideo"
        :autoplay="leftMedia.isAutoplay"
        :loop="leftMedia.isAutoplay"
        :muted="leftMedia.isAutoplay"
        :controls="!leftMedia.isAutoplay"
        playsinline
      >
        <source :src="leftMedia.mediaSource" />
      </video>
      <div
        v-for="(position, name, overlayIndex) in leftMedia.overlays"
        :key="name"
        :class="[`text-with-two-images-block__overlay--${name}`,
          'text-with-two-images-block__overlay']"
      >
        <div class="text-with-two-images-block__overlay-container">
          <div
            v-for="(item, positionIndex) in position"
            :key="item"
            v-html="item.overlay.OverlayBody"
            :class="leftMedia.overlayClasses[overlayIndex][positionIndex]"
          ></div>
        </div>
      </div>
    </component>
  </div>
</template>

<style>
.text-with-two-images-block {
  width: 100%;
  padding: 34px 0 46px 15px;
  max-width: var(--layout-maxWidth);
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.text-with-two-images-block__positioning-wrapper {
  display: flex;
  flex-direction: column-reverse;
}

.text-with-two-images-block__text {
  width: 100%;
  margin-bottom: 40px;
  padding-right: 15px;
}

.text-with-two-images-block__left-media {
  max-width: 571px;
  margin-right: 116px;
  display: block;
  position: relative;
}

.text-with-two-images-block__right-media {
  max-width: 698px;
  margin-left: 47px;
  margin-bottom: 46px;
  display: block;
  position: relative;
}

.text-with-two-images-block__right-media--single {
  margin-bottom: 0;
}

.text-with-two-images-block__left-image,
.text-with-two-images-block__left-video,
.text-with-two-images-block__right-image,
.text-with-two-images-block__right-video {
  width: 100%;
  outline: none;
  display: block;
}

@media (--tabletAndDesktop) {
  .text-with-two-images-block {
    padding: 0 0 52px 52px;
  }

  .text-with-two-images-block__positioning-wrapper {
    display: block;
    padding-top: 52px;
  }

  .text-with-two-images-block__text {
    max-width: 571px;
    width: 42%;
    padding: 0;
    margin-top: 60px;
    margin-bottom: 96px;
  }

  .text-with-two-images-block__left-media {
    width: 42%;
  }

  .text-with-two-images-block__right-media {
    float: right;
    width: 49%;
    margin: 0;
  }
}

.text-with-two-images-block__overlay {
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: var(--layout-maxWidth);
  display: flex;
  flex-direction: column;
  padding: 20px;
  pointer-events: none;
}

.text-with-two-images-block__overlay-item {
  padding: 10px;
  display: none;
  max-height: 100%;
  white-space: normal;
  pointer-events: auto;
}

.text-with-two-images-block__overlay-item h1 {
  letter-spacing: -0.3px;
  font-size: 55px;
  margin: 0;
}

.text-with-two-images-block__overlay-item h2 {
  font-size: 40px;
  margin: 0;
}

.text-with-two-images-block__overlay-item h3 {
  font-size: 30px;
  margin: 0;
}

.text-with-two-images-block__overlay-item p {
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 0.9px;
  margin: 8px 0;
}

@media (max-width: 767px) {
  .text-with-two-images-block__overlay {
    padding: 10px;
  }

  .text-with-two-images-block__overlay-item {
    padding: 5px;
    width: 100%;
  }

  .text-with-two-images-block__overlay-item--phone {
    display: block;
  }

  .text-with-two-images-block__overlay-item--phone-text-left {
    text-align: left;
  }

  .text-with-two-images-block__overlay-item--phone-text-center {
    text-align: center;
  }

  .text-with-two-images-block__overlay-item--phone-text-right {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .text-with-two-images-block__overlay-item--desktop {
    display: block;
  }

  .text-with-two-images-block__overlay-item--desktop-text-left {
    text-align: left;
  }

  .text-with-two-images-block__overlay-item--desktop-text-center {
    text-align: center;
  }

  .text-with-two-images-block__overlay-item--desktop-text-right {
    text-align: right;
  }
}

/* Overlay positions */
.text-with-two-images-block__overlay--top {
  align-items: center;
  justify-content: flex-start;
}

.text-with-two-images-block__overlay--topleft {
  align-items: flex-start;
  justify-content: flex-start;
}

.text-with-two-images-block__overlay--topright {
  align-items: flex-end;
  justify-content: flex-start;
}

.text-with-two-images-block__overlay--left {
  align-items: flex-start;
  justify-content: center;
}

.text-with-two-images-block__overlay--right {
  align-items: flex-end;
  justify-content: center;
}

.text-with-two-images-block__overlay--center {
  align-items: center;
  justify-content: center;
}

.text-with-two-images-block__overlay--bottom {
  align-items: center;
  justify-content: flex-end;
}

.text-with-two-images-block__overlay--bottomleft {
  align-items: flex-start;
  justify-content: flex-end;
}

.text-with-two-images-block__overlay--bottomright {
  align-items: flex-end;
  justify-content: flex-end;
}
</style>
